import React, { useState } from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import ExplanationMessage from "../ExplanationMessage";
import ControlBar from "../ControlBar/ControlBar";
import TestsContainer from "../TestsContainer/TestsContainer";
import AdditionalTestsContainer from "../TestsContainer/AdditionalTestsContainer";
import ProgressBar from "../ProgressBar/ProgressBar";
import logo from "../../assets/images/logo.jpg";
import PrintInfoBox from "../PrintInfoBox";
import LogsComponent from "../LogModal/logs";
import "./MainContent.scss";
import { LogModalProvider } from "../LogModal/LogModalProvider";
import PermissionModal from "../PermissionModal";
import useMediaDevices from "../../helpers/customHooks/usePermissionTracker";
import useExtensionTracker from "../../helpers/customHooks/useExtensionTracker";
import CompanionModal from "../CompanionModal/companionModal";
import { ITestsResult } from "twillio-tests/core/TestResults";

const styles = (theme: any): any => ({
  wrapper: {},
  container: {
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
    padding: "0 15px",
  },
  powered: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 16,
  },
  powered_avatour: {
    width: 200,
    margin: "0 auto",
    paddingBottom: 0,
    background: "#fff",
  },
  poweredText: {
    fontWeight: 600,
    color: "#333",
    fontSize: 10,
  },
  poweredLogo: {
    width: 47,
    height: "auto",
    display: "block",
    marginLeft: 4,
  },
  lobbyWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    maxWidth: "1230px",
    margin: "0 auto",
    padding: "0 15px",
    width: "100%",
  },
  lobbyPage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    marginTop: "-72px",
  },
  actionBtnSection: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  },
  actionBtn: {
    padding: "8px 22px",
    borderRadius: "4px",
    color: "white",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "26px",
    letterSpacing: "0.46px",
    textTransform: "uppercase",
    border: "none",
    backgroundColor: theme.customColors.mainColor,
  },
  whatIsThisText: {
    textDecoration: "none",
    textAlign: "center",
    color: "#3577C1",
  },
  poweredBy: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingBottom: "32px",
    paddingTop: "32px",
    "& span": {
      color: "black",
      fontSize: "12px",
      letterSpacing: "0.4px",
      fontWeight: 400,
      lineHeight: "19.92px",
    },
  },
  formSection: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  formDesc: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& h1": {
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "37.5px",
      textAlign: "center",
      color: "black",
      fontFamily: "Roboto, sans-serif !important",
    },
    "& h6": {
      maxWidth: "480px",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.15px",
      color: "black",
      fontFamily: "Roboto, sans-serif !important",
      fontWeight: 400,
    },
  },
  formInputs: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& input": {
      padding: "5.5px 0",
      fontFamily: "Roboto, sans-serif !important",
    },
    "& label": {
      fontFamily: "Roboto, sans-serif !important",
    },
  },
});
function mapStateToProps(state: any) {
  return {
    backgroundColor: state.document.layout.backgroundColor,
    hidePoweredByRTC: state.document.layout.hidePoweredByRTC,
    theme: state.document.theme,
    config: state.tests.config,
    testIsLoading: state.document.testIsLoading,
    testResult: state.tests.testResult,
  };
}

interface IMainContentProps {
  classes: any;
  backgroundColor?: string;
  hidePoweredByRTC?: boolean;
  theme?: any;
  config?: any;
  testIsLoading?: boolean;
  testResult?: ITestsResult;
}

const wrapperStyle = (backgroundColor?: string) => {
  return {
    backgroundColor: backgroundColor || "unset",
  };
};

export type TestState = "initial" | "form" | "inProgress";

const MainContent = (props: IMainContentProps) => {
  const { classes, backgroundColor, hidePoweredByRTC, theme, config } = props;
  const [testState, setTestState] = useState<TestState>("initial");

  const isCompanionForced = props.config
    ? props.config.options["companion"] && props.config.options["companion"] === "force"
      ? true
      : false
    : false;
  //commenting the actuall condition and hiding the hidePermissionModal for https://redmine.testrtc.com/issues/9451
  // let hidePermissionModal = useMediaDevices(config);
  let hidePermissionModal = true;
  let hideInstallExtensionModal = !isCompanionForced;
  const checkExtension = useExtensionTracker();

  if (isCompanionForced) {
    hideInstallExtensionModal = checkExtension;
  }

  return (
    <LogModalProvider>
      {config?.useNewWidgetDesign ? (
        <div className={classes.lobbyWrapper}>
          {config && config.options.hidewidgets !== "true" && (
            <React.Fragment>
              <ExplanationMessage />
              <PrintInfoBox />
            </React.Fragment>
          )}
          <ControlBar
            hideInstallExtensionModal={hidePermissionModal}
            testState={testState}
            setTestState={setTestState}
            onboardingClasses={classes}
          />
          {config &&
            config.options.hidewidgets !== "true" &&
            props.testResult &&
            Object.keys(props.testResult).length > 0 && (
              <React.Fragment>
                <TestsContainer />
                <AdditionalTestsContainer />
              </React.Fragment>
            )}
          {!hidePoweredByRTC && (
            <div className={classes.poweredBy}>
              <span>Powered by</span>
              <img alt="Powered by" src="/testRTC.png" width={70} />
            </div>
          )}
        </div>
      ) : (
        <div
          className={`${classes.wrapper} main-content-wrapper-${theme}`}
          style={wrapperStyle(backgroundColor)}
        >
          <div className={classes.container}>
            {config && config.options.hidewidgets !== "true" && (
              <React.Fragment>
                <ExplanationMessage />
                <PrintInfoBox />
              </React.Fragment>
            )}
            <ControlBar hideInstallExtensionModal={hideInstallExtensionModal} />
            {config && config.options.hidewidgets !== "true" && (
              <React.Fragment>
                <TestsContainer />
                <AdditionalTestsContainer />
              </React.Fragment>
            )}
            {props.config &&
              props.config.options &&
              props.config.options["report"] === "pdf" &&
              props.config.includeLogs && (
                <React.Fragment>
                  <div className="html2pdf__page-break"></div>
                  <LogsComponent scrollable={false} />
                </React.Fragment>
              )}
            {props.testIsLoading && <CompanionModal open={!hideInstallExtensionModal} />}
            {props.testIsLoading && hideInstallExtensionModal && (
              <PermissionModal open={!hidePermissionModal} />
            )}
            {hidePermissionModal && hideInstallExtensionModal && <ProgressBar />}

            {!hidePoweredByRTC && (
              <a
                className={`poweredBy ${classes.powered} ${classes[`powered_${theme}`]}`}
                href="https://testrtc.com"
                target="_blank"
              >
                <span className={classes.poweredText}>Powered by</span>
                <img className={classes.poweredLogo} src={logo} alt="logo" />
              </a>
            )}
          </div>
        </div>
      )}
    </LogModalProvider>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(MainContent));
