import React, { Dispatch, SetStateAction } from "react";

import { Button, TextField, Typography } from "@material-ui/core";
import ProgressBar from "../ProgressBar/ProgressBar";
import { IMetaState, IValueState, OptionalTooltip } from "../ControlBar/ControlBar";
import { IField } from "../../interfaces";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import Field from "../ControlBar/Field";
import { TestState } from "../MainContent/MainContent";

interface OnboardingProps {
  testState?: TestState;
  setTestState?: Dispatch<SetStateAction<TestState>>;
  theme: any;
  onboardingClasses: any;
  startTest: () => void;
  values: IValueState;
  fields: IField[];
  meta: IMetaState;
  config: TestConfiguration;
  handleChange: (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Onboarding = ({
  testState,
  setTestState,
  theme,
  onboardingClasses,
  startTest,
  values,
  fields,
  meta,
  config,
  handleChange,
}: OnboardingProps) => {
  const arePropsValid = testState && setTestState;

  if (!arePropsValid) return null;

  const handleStartTest = () => {
    startTest();
    setTestState?.("inProgress");
  };

  return (
    <div className={onboardingClasses.lobbyPage}>
      {testState === "initial" && (
        <div className={onboardingClasses.actionBtnSection}>
          <Button onClick={() => setTestState?.("form")} className={onboardingClasses.actionBtn}>
            Start a test
          </Button>
          <a href="#" className={onboardingClasses.whatIsThisText}>
            What is this test
          </a>
        </div>
      )}
      {testState === "form" && (
        <div className={onboardingClasses.formSection}>
          <div className={onboardingClasses.formDesc}>
            <Typography variant="h1">Run a test</Typography>
            <Typography variant="subtitle2">
              To conduct a test, please enter your email address and state the problem you are
              experiencing in the reason field.
            </Typography>
          </div>
          <div className={onboardingClasses.formInputs}>
            {Object.keys(values).length > 0 &&
              fields.map((field) => {
                if (meta[field.name].hidden) {
                  return null;
                }
                if (config?.inviteFields && config.inviteFields[field.name]) {
                  values[field.name] = config.inviteFields[field.name];
                }
                return (
                  <OptionalTooltip
                    key={field.name}
                    title={values[field.name]}
                    show={meta[field.name].readonly}
                  >
                    <Field
                      currentTheme={theme}
                      name={field.name}
                      value={values[field.name]}
                      meta={meta[field.name]}
                      onChange={handleChange(field.name)}
                      config={config}
                      onboardingClasses={onboardingClasses}
                    />
                  </OptionalTooltip>
                );
              })}
          </div>
          <Button
            data-html2canvas-ignore
            id="start"
            onClick={handleStartTest}
            className={onboardingClasses.actionBtn}
          >
            Start a test
          </Button>
        </div>
      )}
      {testState === "inProgress" && <ProgressBar />}
    </div>
  );
};

export default Onboarding;
