/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { ToastContainer } from "react-toastify";
import CustomTheme from "./CustomTheme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { initTests } from "../../actions/actions";
import { IDocumentState } from "../../interfaces";
import MainContent from "../MainContent/MainContent";
import html2pdf from "html2pdf.js";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { delay } from "lodash";
import NewMainContent from "../MainContent/NewMainContent";
interface IAppProps {
  document: IDocumentState;
  initTests(): void;
  config: TestConfiguration;
}

function mapStateToProps(state: any) {
  return {
    document: state.document,
    config: state.tests.config,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    initTests() {
      dispatch(initTests());
    },
  };
}

const wrapperStyle = (backgroundColor?: string) => {
  return {
    backgroundColor: backgroundColor || "unset",
  };
};

const App = (props: IAppProps) => {
  const {
    document: { layout, isLoading, theme, uuid },
    initTests,
  } = props;
  React.useEffect(() => {
    initTests();
  }, []);

  React.useEffect(() => {
    const options = props.config?.options;
    if (options && options["report"] === "pdf") {
      delay(() => exportPDF(), 200);
    }
  }, [props.config]);

  React.useEffect(() => {
    if (!isLoading) {
      document.getElementById("root-spinner")?.remove();
    }
  }, [isLoading]);

  const exportPDF = async () => {
    // setPdfDownloading(true);
    const element = document.getElementsByClassName("pdfReport")[0];
    const opt = {
      pagebreak: { avoid: "img" },
      margin: theme === "livevox" ? [14, 0, 20, 0] : [10, 10, 10, 10],
      filename: `network-test-highlights-${uuid}.pdf`,
      html2canvas: { windowWidth: 959 },
      jsPDF: { orientation: "p", unit: "pt", format: "letter" },
    };
    // New Promise-based usage:
    await html2pdf().from(element).set(opt).save();
    // setPdfDownloading(false);
  };

  const useNewDesign = props.config ? props.config.useNewWidgetDesign : null;
  return (
    <MuiThemeProvider theme={CustomTheme(theme)}>
      <CssBaseline />
      <ToastContainer />
      {useNewDesign ? (
        <div className="new-wrapper">
          <div className="header-wrapper" dangerouslySetInnerHTML={{ __html: layout.header }} />
          <MainContent />
        </div>
      ) : (
        <div
          className={`pdfReport wrapper report-${layout.report}`}
          style={wrapperStyle(layout.backgroundColor)}
        >
          <div className="header-wrapper" dangerouslySetInnerHTML={{ __html: layout.header }} />
          <MainContent />
          <div
            data-html2canvas-ignore
            className="footer-wrapper"
            dangerouslySetInnerHTML={{ __html: layout.footer }}
          />
        </div>
      )}
    </MuiThemeProvider>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
