import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from "@material-ui/core/Badge";
import { TooltipIco, AvatourTooltipIco } from "../icons";
import { ICoordinates, ITestsResult } from "twillio-tests/core/TestResults";
import { LogModalContext } from "../LogModal/LogModalProvider";

const styles = (theme: any) => ({
  container: {
    color: theme.palette.primary.main,
    display: "flex",
    paddingBottom: 15,
    borderBottom: "1px solid #c9cbd1",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative" as "relative",
  },
  icon: {
    position: "relative" as "relative",
    width: 25,
    "& svg": {
      display: "block",
    },
  },
  iconClickable: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon_freshworks: {
    "& svg": {
      fill: theme.palette.primary.dark,
    },
  },
  icon_avatour: {
    "& svg": {
      fill: "#fff",
    },
  },
  icon_dialpad: {
    color: "rgb(124, 82, 255)",
  },
  icon_salesloft: {
    "& svg": {
      fill: "#054a2e",
    },
  },
  icon_livevox: {
    "& svg": {
      fill: "#005eb8",
    },
  },
  container_salesloft: {
    background: "#f2f2f2",
    borderRadius: "5px",
    padding: "5px",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    margin: "0 30px",
    textTransform: "uppercase" as "uppercase",
    position: "absolute" as "absolute",
    left: 0,
    right: 0,
    textAlign: "center" as "center",
    lineHeight: 1,
  },
  title_mend: {
    fontWeight: 500,
    color: "rgb(27, 111, 144)",
  },
  title_logitech: {
    fontWeight: 700,
    color: "black",
  },
  title_amazon: {
    fontWeight: 400,
  },
  title_salesloft: {
    color: "#054a2e",
  },
  title_freshworks: {
    textAlign: "left" as "left",
  },
  title_glia: {
    color: "#7c19dd",
  },
  title_puzzel: {
    color: "#eb2e63",
    fontWeight: 700,
    fontFamily: "Quicksand, sans-serif",
  },
  title_sunriver: {
    fontFamily: "Arima, cursive",
    letterSpacing: "unset",
    fontWeight: 600,
  },
  title_livevox: {
    color: "#005eb8",
  },
  title_avatour: {
    color: "#fff",
  },
  title_dialpad: {
    color: "rgb(124, 82, 255)",
    fontSize: 19,
  },
  tooltip: {
    display: "block",
  },
  link: {
    color: theme.customColors.linkColor,
    fontSize: 14,
    fontWeight: 400,
    display: "block",
    textAlign: "center" as "center",
    lineHeight: 1.07,
    marginRight: 14,
  },
  link_freshworks: {
    color: theme.palette.secondary.main,
  },
  link_amazon: {
    color: theme.palette.secondary.main,
  },
  link_avatour: {
    color: "#fff",
  },
  link_dialpad: {
    color: "rgb(124, 82, 255)",
  },
  link_livevox: {
    color: "#005eb8",
  },
  disabled: {
    pointerEvents: "none" as "none",
    opacity: 0.3,
  },
  right: {
    display: "flex",
    alignItems: "center",
    position: "relative" as "relative",
    zIndex: 2,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltipLink: {
    color: "inherit",
  },
  tooltipLink_freshworks: {
    color: theme.palette.secondary.main,
  },
  tooltipLink_avatour: {
    color: "#fff",
  },
  tooltipLink_dialpad: {
    color: "rgb(124, 82, 255)",
  },
  tooltipLink_livevox: {
    color: "#005eb8",
  },
  tooltipLink_salesloft: {
    "& svg": {
      fill: "#054a2e",
    },
  },
  testError: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
    flexGrow: 1,
    textAlign: "center" as "center",
    wordBreak: "break-word" as "break-word",
  },
  testError_avatour: {
    color: theme.customColors.errorColor,
  },
  testError_dialpad: {
    color: theme.customColors.errorColor,
  },
  iconColor_mend: {
    color: "rgb(235, 28, 36)",
  },
});

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
    currentTheme: state.document.theme,
  };
}

interface ITestHeaderProps {
  classes: any;
  icon: JSX.Element;
  title: string;
  tooltip: string;
  showInMap?: boolean;
  location?: {
    coordinates: ICoordinates | null;
  };
  testResult?: ITestsResult;
  error?: string;
  errorsCount?: number;
  children: any; // muly:TBD type
  knowledgeUrl?: string;
  currentTheme?: string;
  estimateTime?: number;
  testName?: string;
}

const TestHeader = (props: ITestHeaderProps) => {
  const logModalContext = React.useContext(LogModalContext);
  const {
    classes,
    icon,
    title,
    tooltip,
    showInMap,
    location,
    testResult,
    error,
    children,
    knowledgeUrl,
    currentTheme,
    estimateTime,
    errorsCount,
    testName,
  } = props;
  const tooltipElem = (
    <>
      <div className="arrow" />
      {tooltip}
      {estimateTime && (
        <>
          <br />
          {`${estimateTime}s`}
        </>
      )}
    </>
  );

  let locationLink = "";
  if (location && location.coordinates) {
    const { latitude, longitude } = location.coordinates;
    locationLink = `https://maps.google.com/maps?ll=${latitude},${longitude}`;
  }
  const iconBadgeContent = errorsCount ? errorsCount : error ? 1 : 0;
  const renderTooltipIcon = () => {
    switch (currentTheme) {
      case "avatour":
        return <AvatourTooltipIco />;
      default:
        return <TooltipIco />;
    }
  };
  return (
    <>
      <div className={`${classes.container} ${classes[`container_${currentTheme}`]}`}>
        <div
          className={`${classes.icon} ${classes[`icon_${currentTheme}`]} ${
            testResult ? classes.iconClickable : ""
          }`}
          onClick={() => testResult && logModalContext.setOpen(true, testName)}
        >
          <Badge
            badgeContent={iconBadgeContent}
            color="error"
            className={`${classes[`iconColor_${currentTheme}`]}`}
          >
            {icon}
          </Badge>
        </div>
        <span
          className={`${classes.title} ${classes[`title_${currentTheme}`]} title-${currentTheme}`}
          id={`${testName}`}
        >
          {title}
        </span>
        <div className={`${classes.right}`}>
          {showInMap && (
            <a
              href={locationLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`${classes.link} ${classes[`link_${currentTheme}`]} ${
                testResult === null ? classes.disabled : ""
              }`}
            >
              <Trans ns="tests" i18nKey="location.showOnMap">
                Show <br /> on map
              </Trans>
            </a>
          )}
          <Tooltip title={tooltipElem} placement="top">
            {knowledgeUrl !== "-" ? (
              <a
                href={knowledgeUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={`${classes.tooltipLink} ${classes[`tooltipLink_${currentTheme}`]} ${
                  classes[`iconColor_${currentTheme}`]
                }`}
              >
                {renderTooltipIcon()}
              </a>
            ) : (
              renderTooltipIcon()
            )}
          </Tooltip>
        </div>
      </div>
      {!!error && (
        <div className={`${classes.testError} ${classes[`testError_${currentTheme}`]}`}>
          <p id={`${testName}-error`}>{error}</p>
        </div>
      )}
      {!error && children}
    </>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(TestHeader));
